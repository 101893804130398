import en from './translations/en.json'
import nl from './translations/nl.json'
import fr from './translations/fr.json'
import de from './translations/de.json'

export default {
    en,
    nl,
    fr,
    de
}