import { createApp } from 'vue'
import { createGtm } from '@gtm-support/vue-gtm';
// import { createGtm } from "vue-gtm";
import App from './App.vue'
import router from '@/router'
import { store, key } from '@/store'
import { createI18n } from 'vue-i18n'
import messages from './lang'
import "./../sass/sliderstyles/default.css";

const app = createApp(App).use(router)

const i18n = createI18n({
  locale: 'nl',
  legacy: false,
  globalInjection: true,
  fallBackLocale: 'en',
  messages
})

if(process.env.NODE_ENV === "development" || process.env.NODE_ENV === "production"){
  app.use(
    createGtm({
      id: 'GTM-N8FN57', 
      defer: false, 
      compatibility: false, 
      enabled: true,
      debug:false,
      loadScript: true, 
    }),
  )
}

app.use(i18n)
app.provide('i18n', i18n)
app.use(store, key)
app.mount('#app')

// to ungresiter the service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister()
    }
  })
}