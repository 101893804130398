import { audioUrlBody, audioUrlResponse, vogTempUserPayload, vogUserData, saveUserDatapayload } from './../types/index';
import { BaseApi } from './base'
import { AxiosRequestConfig } from 'axios'
import router from '@/router'
import { AxiosError } from 'axios'

class NoAuthApi extends BaseApi {
    public constructor() {
        super(process.env.VUE_APP_API_URL)
        this.instance.interceptors.response.use(this._handleResponse, this._handleError)
        this.instance.interceptors.request.use(this._handleRequest)
    }

    public getAudioUrl = (payload: audioUrlBody) => this.instance.post<audioUrlResponse>("audio", payload)
    public getTempUserData = (payload: vogTempUserPayload) => this.instance.post<vogUserData[]>("item", payload)
    public saveUserData = (payload: saveUserDatapayload) => this.instance.post<vogUserData>("item", payload)

    protected _handleRequest = (config: AxiosRequestConfig) => {
        config.withCredentials = false
        return config
    }
    protected _handleError = async (error: AxiosError) => {
        if (error.response) {
            if (!error.request.responseURL.includes('/audio')) {
                const { status } = error.response

                const routeQuery = router.currentRoute.value.query;
                switch (status) {
                    case 401:
                        return Promise.reject(error)
                    default:
                        // router.push({ name: 'ErrorPage', query: { cur: routeQuery.cur } })
                        if (routeQuery.projectId && routeQuery.token) {
                            router.push({ name: 'ErrorPage', query: { projectId: routeQuery.projectId, token: routeQuery.token, lang: routeQuery.lang, apiUrl: routeQuery.apiUrl } })
                        } else if (!routeQuery.projectId && routeQuery.token) {
                            router.push({ name: 'ErrorPage', query: { token: routeQuery.token, lang: routeQuery.lang, apiUrl: routeQuery.apiUrl } })
                        } else if (!routeQuery.projectId || !routeQuery.token) {
                            router.push({ name: 'ErrorPage', query: { lang: routeQuery.lang, cur: routeQuery.cur } })
                        }
                        // router.push({ name: 'ErrorPage', query: { projectId: routeQuery.projectId }})
                        return Promise.reject(error)
                }
            } else if (error.request.responseURL.includes('/audio')) {
                const punctuationError = "rpc error: code = InvalidArgument desc = This request contains sentences that are too long. Consider splitting up long sentences with sentence ending punctuation e.g. periods. Also consider removing SSML sentence tags (e.g. '<s>') as they can confuse Cloud Text-to-Speech."
                const err = error.response.data.error
                if (err === punctuationError) {
                    return Promise.reject("PunctuationError")
                }
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            return Promise.reject(error)
        }
        else {
            // Something happened in setting up the request that triggered an Error
            return Promise.reject(error)
        }
    }
}

const noAuthApi: NoAuthApi = new NoAuthApi()

export default noAuthApi
