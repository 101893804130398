import { MutationTree } from 'vuex'
import { State } from './index'
import { currentUserResponseType, } from '@/types'

export enum AuthMutationTypes {
    SET_USER = 'SET_USER',
    SET_LOADER = 'SET_LOADER',
}

export type Mutations<S = State> = {
    [AuthMutationTypes.SET_USER](state: S, payload: currentUserResponseType): void;
    [AuthMutationTypes.SET_LOADER](state: S, payload: boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [AuthMutationTypes.SET_USER](state, payload) {
        state.currentUser = payload
    },
    [AuthMutationTypes.SET_LOADER](state, payload) {
        state.loader = payload
    },
}
