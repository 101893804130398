import { vogUserData, voicesLanguagesResponseType, projectData, selectedVoice, projectPagesUserData } from '@/types';
import { MutationTree } from 'vuex'
import { State } from './index'

export enum VoicesMutationTypes {
  SET_VOICES_AND_LANGUAGES = 'SET_VOICES_AND_LANGUAGES',
  SET_USER_DATA = "SET_USER_DATA",
  SET_PROJECT_DATA = "SET_PROJECT_DATA",
  SET_PROJECT_PAGES_USER_DATA = "SET_PROJECT_PAGES_USER_DATA",
  SET_LOADER = 'SET_LOADER',
  SET_API_ERROR_LOADER = 'SET_API_ERROR_LOADER',
  SET_FUNC_ERROR = 'SET_FUNC_ERROR',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_TEMP_SELECTED_LANGUAGE = 'SET_TEMP_SELECTED_LANGUAGE',
  SET_SELECTED_LANGUAGE = 'SET_SELECTED__LANGUAGE',
  SET_SELECTED_VOICE = 'SET_SELECTED_VOICE',
  SET_VOICE_SPEED_VALUE = 'SET_VOICE_SPEED_VALUE',
  SET_VOICE_PITCH_VALUE = 'SET_VOICE_PITCH_VALUE',
  SET_VOICE_SILENCE_VALUE = 'SET_VOICE_SILENCE_VALUE',
  SET_VOICE_EMPHASIS_VALUE = 'SET_VOICE_EMPHASIS_VALUE',
  SET_AUDIO_URL = 'SET_AUDIO_URL',
  SET_TEMP_USER_DATA = "SET_TEMP_USER_DATA",
}

export type Mutations<S = State> = {
  [VoicesMutationTypes.SET_VOICES_AND_LANGUAGES](state: S, payload: Array<voicesLanguagesResponseType>): void;
  [VoicesMutationTypes.SET_USER_DATA](state: S, payload: vogUserData): void;
  [VoicesMutationTypes.SET_PROJECT_DATA](state: S, payload: projectData): void;
  [VoicesMutationTypes.SET_PROJECT_PAGES_USER_DATA](state: S, payload: projectPagesUserData): void;
  [VoicesMutationTypes.SET_LOADER](state: S, payload: boolean): void;
  [VoicesMutationTypes.SET_API_ERROR_LOADER](state: S, payload: boolean): void;
  [VoicesMutationTypes.SET_FUNC_ERROR](state: S, payload: string): void;
  [VoicesMutationTypes.SET_LANGUAGE](state: S, payload: Array<string>): void;
  [VoicesMutationTypes.SET_TEMP_SELECTED_LANGUAGE](state: S, payload: string): void;
  [VoicesMutationTypes.SET_SELECTED_LANGUAGE](state: S, payload: string): void;
  [VoicesMutationTypes.SET_SELECTED_VOICE](state: S, payload: selectedVoice[]): void;
  [VoicesMutationTypes.SET_VOICE_SPEED_VALUE](state: S, payload: number): void;
  [VoicesMutationTypes.SET_VOICE_PITCH_VALUE](state: S, payload: number): void;
  [VoicesMutationTypes.SET_VOICE_SILENCE_VALUE](state: S, payload: string): void;
  [VoicesMutationTypes.SET_VOICE_EMPHASIS_VALUE](state: S, payload: string): void;
  [VoicesMutationTypes.SET_AUDIO_URL](state: S, payload: string): void;
  [VoicesMutationTypes.SET_TEMP_USER_DATA](state: S, payload: Array<vogUserData>): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [VoicesMutationTypes.SET_VOICES_AND_LANGUAGES](state, payload) {
    state.voicesAndLanguages = payload
  },
  [VoicesMutationTypes.SET_USER_DATA](state, payload) {
    state.userData = payload
  },
  [VoicesMutationTypes.SET_PROJECT_DATA](state, payload) {
    state.projectData = payload
  },
  [VoicesMutationTypes.SET_PROJECT_PAGES_USER_DATA](state, payload) {
    state.projectPagesUserData = payload
  },
  [VoicesMutationTypes.SET_LOADER](state, payload) {
    state.loader = payload
  },
  [VoicesMutationTypes.SET_API_ERROR_LOADER](state, payload) {
    state.apiErrorLoader = payload
  },
  [VoicesMutationTypes.SET_FUNC_ERROR](state, payload) {
    state.funcError = payload
  },
  [VoicesMutationTypes.SET_LANGUAGE](state, payload) {
    state.language = payload
  },
  [VoicesMutationTypes.SET_TEMP_SELECTED_LANGUAGE](state, payload) {
    state.tempSelectedLanguage = payload
  },
  [VoicesMutationTypes.SET_SELECTED_LANGUAGE](state, payload) {
    state.selectedLanguage = payload
  },
  [VoicesMutationTypes.SET_SELECTED_VOICE](state, payload) {
    state.selectedVoice = payload
  },
  [VoicesMutationTypes.SET_VOICE_SPEED_VALUE](state, payload) {
    state.voiceSpeedValue = payload
  },
  [VoicesMutationTypes.SET_VOICE_PITCH_VALUE](state, payload) {
    state.voicePitchValue = payload
  },
  [VoicesMutationTypes.SET_VOICE_SILENCE_VALUE](state, payload) {
    state.voiceSilenceValue = payload
  },
  [VoicesMutationTypes.SET_VOICE_EMPHASIS_VALUE](state, payload) {
    state.voiceEmphasisValue = payload
  },
  [VoicesMutationTypes.SET_AUDIO_URL](state, payload) {
    state.setAudioURL = payload
  },
  [VoicesMutationTypes.SET_TEMP_USER_DATA](state, payload) {
    state.tempUserData = payload
  },
}
