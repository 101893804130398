import { ActionContext, ActionTree } from 'vuex'
import { Mutations, AuthMutationTypes } from './mutations'
import { State } from './index'
import { State as RootState } from '@/store'
import authApi from '@/api/Auth'

export enum AuthActionTypes {
    GET_CURRENT_USER_DETAILS = 'GET_CURRENT_USER_DETAILS',
}

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [AuthActionTypes.GET_CURRENT_USER_DETAILS]({ commit }: AugmentedActionContext, payload: void): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [AuthActionTypes.GET_CURRENT_USER_DETAILS]({ commit }) {
        commit(AuthMutationTypes.SET_LOADER, true)
        const res = await authApi.getCurrentUSer()
        commit(AuthMutationTypes.SET_USER, res['currentUser'])
        commit(AuthMutationTypes.SET_LOADER, false)
    }
}

