import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import auth, { State as authState, Store as authStore } from './auth'
import voices, { State as voicesState, Store as voicesStore } from './voices'


export interface State {
  auth: authState;
  voices: voicesState;
}

export type RootStoreAuth = authStore<Pick<State, 'auth'>>
export type RootStoreVoices = voicesStore<Pick<State, 'voices'>>

export const key: InjectionKey<Store<State>> = Symbol('store')

export const store = createStore<State>({
  modules: {
    auth,
    voices
  }
})

export function useStore() {
  return baseUseStore(key)
}