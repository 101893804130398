<template>
  <div class="main-container">
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'VoiceOverGenerator'
})
</script>
